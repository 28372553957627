/*---------- Importing fonts ----------*/

@font-face {
  font-family: "SL Gothic Text Regular";
  src: url(../assets/fonts/SLGothicText2-Regular.eot); /* IE9 Compat Modes */
  src: url(../assets/fonts/SLGothicText2-Regular.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(../assets/fonts/SLGothicText2-Regular.woff2) format('woff2'), /* Super Modern Browsers */
       url(../assets/fonts/SLGothicText2-Regular.woff) format('woff'), /* Pretty Modern Browsers */
}
@font-face {
  font-family: "SL Gothic Text Regular Italic";
  src: url(../assets/fonts/SLGothicText2-RegularItalic.eot); /* IE9 Compat Modes */
  src: url(../assets/fonts/SLGothicText2-RegularItalic.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(../assets/fonts/SLGothicText2-RegularItalic.woff2) format('woff2'), /* Super Modern Browsers */
       url(../assets/fonts/SLGothicText2-RegularItalic.woff) format('woff'), /* Pretty Modern Browsers */
}
@font-face {
  font-family: "SL Gothic Text Medium";
  src: url(../assets/fonts/SLGothicText2-Medium.eot); /* IE9 Compat Modes */
  src: url(../assets/fonts/SLGothicText2-Medium.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(../assets/fonts/SLGothicText2-Medium.woff2) format('woff2'), /* Super Modern Browsers */
       url(../assets/fonts/SLGothicText2-Medium.woff) format('woff'), /* Pretty Modern Browsers */
}
@font-face {
  font-family: "SL Gothic Text Medium Italic";
  src: url(../assets/fonts/SLGothicText2-MediumItalic.eot); /* IE9 Compat Modes */
  src: url(../assets/fonts/SLGothicText2-MediumItalic.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(../assets/fonts/SLGothicText2-MediumItalic.woff2) format('woff2'), /* Super Modern Browsers */
       url(../assets/fonts/SLGothicText2-MediumItalic.woff) format('woff'), /* Pretty Modern Browsers */
}
@font-face {
  font-family: "SL Gothic Text Bold";
  src: url(../assets/fonts/SLGothicText2-Bold.eot); /* IE9 Compat Modes */
  src: url(../assets/fonts/SLGothicText2-Bold.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(../assets/fonts/SLGothicText2-Bold.woff2) format('woff2'), /* Super Modern Browsers */
       url(../assets/fonts/SLGothicText2-Bold.woff) format('woff'), /* Pretty Modern Browsers */
}
@font-face {
  font-family: "SL Gothic Text Bold Italic";
  src: url(../assets/fonts/SLGothicText2-BoldItalic.eot); /* IE9 Compat Modes */
  src: url(../assets/fonts/SLGothicText2-BoldItalic.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(../assets/fonts/SLGothicText2-BoldItalic.woff2) format('woff2'), /* Super Modern Browsers */
       url(../assets/fonts/SLGothicText2-BoldItalic.woff) format('woff'), /* Pretty Modern Browsers */
}
@font-face {
  font-family: "SL Gothic Display Bold";
  src: url(../assets/fonts/SLGothicDisplay-Bold.woff2) format('woff2'), /* Super Modern Browsers */
       url(../assets/fonts/SLGothicDisplay-Bold.woff) format('woff'), /* Pretty Modern Browsers */
}
