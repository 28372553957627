/*---------- Base Variables ----------*/
$breakpoints: (
  "small": (
    min-width: 767px,
  ),
  "medium": (
    min-width: 992px,
  ),
  "large": (
    min-width: 1200px,
  ),
  "xxx-large": (
    min-width: 2000px,
  ),
) !default;

/*---------- Color themes ----------*/

@mixin yellow-theme {
  background-color: $yellow;
  color: $dark-red;
}

@mixin red-theme {
  background-color: $red;
  color: $light-yellow;
}

@mixin green-theme {
  background-color: $dark-green;
  color: $yellow;
}
// write "@include yellow-theme" and it will include all the styling inside the mixin

/*---------- Breakpoints ----------*/

/*
	How to use:
	@include breakpoint('small') {
		color: blue;
	}
	
	Translates to:
	@media (min-width: 767px) {
		color: blue;
	}
	*/

@mixin breakpoint($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
				  + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
