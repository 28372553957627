@import "_mixin";
@import "_typography";

/*---------- Base Variables ----------*/
$base-font-size: 16px; //1rem
$header-font-size: 2.875rem; //46px
$title-font-size: 2.25rem; //36px
$title-font-size-desktop: 5rem; //80px
$second-title-font-size: 1.37rem; //22px
$second-title-font-size-large: 1.5rem; //22px
$body-font-size: 1.25rem; //20px
$body-font-size-large: 1.5rem; //24px
$small-font-size: 1.12rem; //18px

$navigation-green: #00282d;
$dark-green: #004b5a;
$red: #b61615;
$dark-red: #ae2424;
$yellow: #ffcd05;
$light-yellow: #ffce0a;

/*---------- Normalize css ----------*/
* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  @include green-theme;
  scroll-behavior: smooth;

  @media (prefers-reduced-motion) {
    scroll-behavior: auto;
  }
}

.main {
  width: 100%;
  margin: 0 auto;
  position: relative;
  @include breakpoint("xxx-large") {
    width: 75%;
  }
}

button {
  border: 0;
  font-family: "SL Gothic Text Bold";
  cursor: pointer;
}

/*---------- Misc ----------*/

.no-break {
  white-space: nowrap;
}

.not-found {
  text-align: center;
  height: 100vh;
}

/*---------- Start screen ----------*/

.start-screen {
  width: calc(100vw - 20px);
  height: calc(100vh - 20px);
  position: fixed;
  top: 0;
  left: 0;
  margin: 10px;
  background-color: $yellow;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 200ms;

  @media (min-width: 768px) {
    .animation-mobile {
      display: none !important;
      visibility: hidden !important;
    }
    .animation-tablet {
      display: none !important;
      visibility: hidden !important;
    }
    .animation-desktop {
      max-width: 100vw;
      height: 100%;
    }
  }
  @media (max-width: 767px) {
    .animation-mobile {
      max-width: 100vw;
      height: 100%;
    }
    .animation-tablet {
      display: none !important;
      visibility: hidden !important;
    }
    .animation-desktop {
      display: none !important;
      visibility: hidden !important;
    }
  }
}

/*---------- Header ----------*/

header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: $dark-green;
  z-index: 100;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4.12rem;
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    background-color: $navigation-green;

    @include breakpoint("medium") {
      height: 100%;
      padding: 0.5rem 2.5rem;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;
    background-color: $navigation-green;
    transition-property: height;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;

    @include breakpoint("medium") {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 0;
      border-top: none;
      height: 100%;
    }

    @media (prefers-reduced-motion) {
      transition-property: none;
      transition-timing-function: none;
      transition-duration: none;
    }

    p,
    a {
      font-size: $title-font-size;
      color: $yellow;
      margin: 1rem 0;
      text-decoration: none;

      @include breakpoint("medium") {
        margin: 0 0 0 2.81rem;
        font-size: $body-font-size;
        color: white;
        font-family: "SL Gothic Text Bold";

        &:hover {
          color: $yellow;
          text-decoration: underline;
        }
      }
    }

    .navs-container {
      margin: 0 1rem 1.25rem;
      padding-top: 3.12rem;
      border-top: 1px solid white;

      button {
        font-size: $title-font-size;
        color: $yellow;
        width: auto;
        margin-bottom: 1rem;
        padding: 0 0 2.5rem;
      }
    }
  }

  button {
    background-color: transparent;
    font-size: $body-font-size;
    color: white;
    display: flex;
    align-self: center;
    padding: 0;
    margin: 0;
    height: 25px;

    .icon-wrapper {
      height: 25px;
      width: 25px;
      margin-right: 5px;
      margin-left: 5px;
    }

    svg {
      fill: white;
    }

    @include breakpoint("medium") {
      margin: 0 0 0 2.81rem;

      &:hover {
        color: $yellow;
        text-decoration: underline;

        svg {
          fill: $yellow;
        }
      }
    }
  }
}

/*---------- Hero ----------*/

.hero {
  height: calc(100vh - 4.12rem);
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  // background-color: $yellow;

  @media (prefers-reduced-motion) {
    background-image: url("/hero.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    width: auto;
    z-index: 1;
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    //hide bottom control for video on IE
    min-height: calc(100% + 80px);

    @media (prefers-reduced-motion) {
      display: none;
    }
  }

  .hero-text {
    z-index: 3;
    display: block;
    background-color: $dark-green;
    font-family: SL Gothic Display Bold;
    font-size: 10vw;
    width: 90%;
    padding: 20px 20px;
    position: absolute;
    bottom: 80px;
    left: 5%;
    line-height: 130%;
    letter-spacing: -0.5px;
    margin: 0;

    @include breakpoint("small") {
      width: 43%;
      padding: 20px 50px;
      font-size: 4vw;
      left: 40px;
    }
  }

  .video-button-container {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    width: 90%;
    top: 1rem;
    right: 5%;

    @include breakpoint("small") {
      bottom: 80px;
      right: 40px;
      top: auto;
    }

    .play-pause {
      padding: 0.5rem;
      width: 4.5rem;
      height: 35px;
      background-color: $dark-green;
      color: $yellow;
    }
  }
}

/*---------- Section ----------*/

section {
  box-sizing: border-box;
  padding: 0rem;
  margin: 0rem;
  position: relative;
  background-color: $dark-green;

  img {
    width: 100%;
    height: 100%;
    @include breakpoint("medium") {
      width: 100%;
    }
  }

  .svg-wrapper {
    width: 100%;
    height: 30vh;
    overflow: hidden;

    svg {
      height: 100%;
      width: 100%;
    }

    @include breakpoint("medium") {
      height: 100%;
      width: 100%;
      margin: 0 auto;
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
    }
  }

  .yellow {
    @include yellow-theme;
    box-sizing: border-box;
    padding: 0rem;
    margin: 0rem;

    @include breakpoint("medium") {
      height: 100%;
    }
  }

  .red {
    @include red-theme;
    box-sizing: border-box;
    padding: 0rem;
    margin: 0rem;

    @include breakpoint("medium") {
      height: 100%;
    }
  }

  .container {
    padding: 1.62rem 1rem 1rem 1rem;

    .text-media-container {
      @include breakpoint("medium") {
        margin: 0.5rem 0 5rem;
        width: 100%;
        // position: relative;
        display: flex;
        justify-content: space-between;
      }

      .media {
        @include breakpoint("medium") {
          display: inline-block;
          width: 50%;
          margin: 0;
          height: auto;
          // position: relative;
          background-color: transparent;
        }
      }

      .text-container {
        @include breakpoint("medium") {
          display: inline-block;
          max-width: 45%;
          margin: 0;
          height: auto;
          // position: relative;
          // margin-bottom: 5rem;
        }
        h2 {
          width: 75%;
        }
      }

      .textRight {
        padding-left: 2rem;
      }

      .textLeft {
        padding-right: 2rem;
      }
    }

    @include breakpoint("medium") {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      padding: 6.87rem 2.5rem 2.5rem;
      position: relative;
      z-index: 1;
      height: auto;
      max-width: 100%;
      margin: 0;
    }

    &-accessibility {
      @extend .container;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;

      h1 {
        font-family: "SL Gothic Display Bold";
        font-size: $title-font-size;
        margin-top: 0;
        margin-bottom: $body-font-size-large;
        letter-spacing: -1px;
        line-height: 110%;

        @include breakpoint("medium") {
          font-size: $header-font-size;
          line-height: 100%;
          width: 100%;
        }
      }

      h2 {
        font-family: "SL Gothic Display Bold";
        color: $yellow;
        font-size: $body-font-size-large;
        letter-spacing: 0;
        line-height: 110%;
        margin-bottom: -1rem;
        margin-top: 2rem;

        @include breakpoint("medium") {
          font-size: $body-font-size-large;
          line-height: 100%;
          width: 100%;
        }
      }

      h3 {
        font-family: "SL Gothic Text Regular";
        font-size: $small-font-size;
        font-weight: normal;
        color: $yellow;
        margin-top: 2rem;
      }

      ul {
        padding-left: 1rem;

        li {
          list-style: none;
        }
        li:before {
          content: "•";
          position: absolute;
          left: 16px;
          font-size: 20px;
          line-height: 26px;

          @include breakpoint("medium") {
            left: 42px;
          }
        }
      }

      p,
      li {
        font-size: $body-font-size;
        color: $yellow;
      }

      a {
        color: $yellow;
        font-family: "SL Gothic Text Regular";

        &:hover {
          color: white;
        }
      }

      @include breakpoint("small") {
        min-width: auto;
        max-width: 500px;
      }

      @include breakpoint("medium") {
        min-width: auto;
        max-width: 600px;
      }
    }
  }

  .section-name {
    font-family: "SL Gothic Text Bold";
    font-size: $small-font-size;
    margin-bottom: 0.65rem;

    @include breakpoint("medium") {
      font-size: $body-font-size-large;
      line-height: 160%;
      margin-bottom: 1.25rem;
    }
  }
}

/*---------- Info-boxes ----------*/

.info-container {
  margin-bottom: 2.1rem;
  margin: 3.1rem 0 2.1rem 0;
  background-color: transparent;

  @include breakpoint("medium") {
    display: flex;
    height: auto;
  }
}

.info-box {
  @include yellow-theme;
  padding: 0.93rem;
  margin: 0 0 0.93rem;
  width: auto;
  height: auto;

  @include breakpoint("medium") {
    width: 33%;
    padding: 1.87rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    h2 {
      font-size: $second-title-font-size;
      margin: 0;
    }
  }

  .content-container {
    height: calc(100% - 1.87rem);
    border-top: 1px solid $red;
    overflow: hidden;
    transition-property: max-height, opacity, margin-top;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-duration: 200ms;

    @media (prefers-reduced-motion) {
      transition-property: none;
      transition-timing-function: none;
      transition-duration: none;
    }

    ul {
      padding-left: 1rem;

      li {
        list-style: none;
      }
      li:before {
        content: "•";
        position: absolute;
        left: 16px;
        font-size: 20px;
        line-height: 26px;

        @include breakpoint("medium") {
          left: 32px;
        }
      }
    }

    @include breakpoint("medium") {
      margin-top: 1rem;

      p,
      li {
        font-size: $body-font-size;
      }
    }
  }

  a {
    @include breakpoint("medium") {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .info-link {
    @include red-theme;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: max-content;
    align-self: end;
    width: 100%;
    box-sizing: border-box;
    font-family: "SL Gothic Text Bold";
    text-align: left;
    padding: 0.93rem;
    margin: 1.62rem 0 0.5rem 0;
    font-size: $second-title-font-size;
    text-decoration: none;

    @include breakpoint("medium") {
      max-width: 300px;
      width: 100%;
      margin: 5.62rem auto 0.5rem;

      &:hover {
        background-color: #860f0f;
      }
    }
  }
  svg {
    margin-top: 2px;
  }
}

/*---------- Typography ----------*/

h1 {
  font-family: "SL Gothic Display Bold";
  font-size: $header-font-size;
  letter-spacing: -1px;
}

h2 {
  font-family: "SL Gothic Display Bold";
  font-size: $title-font-size;
  margin-top: 0;
  letter-spacing: -1px;
  line-height: 110%;
  width: 75%;

  @include breakpoint("medium") {
    font-size: $title-font-size-desktop;
    line-height: 100%;
    margin-bottom: 2.5rem;
    width: 100%;
  }
}

h3 {
  font-family: "SL Gothic Text Medium";
  font-size: $second-title-font-size;
  margin: 0;
  letter-spacing: normal;

  @include breakpoint("medium") {
    font-size: $body-font-size-large;
    line-height: 100%;
  }
}

a {
  font-family: "SL Gothic Text Regular";
  color: inherit;
  font-size: $body-font-size;
}

p,
li {
  font-family: "SL Gothic Text Regular";
  font-size: $body-font-size;
  line-height: 120%;
  letter-spacing: normal;

  @include breakpoint("medium") {
    font-size: $body-font-size-large;
    line-height: 125%;
  }
}

.small-text {
  font-family: "SL Gothic Text Regular";
  font-size: 1rem;
}

/*---------- Gallery ----------*/

.embla {
  // overflow: hidden;
  display: flex;
  align-items: center;
  // width: auto;
  position: relative;
  // max-width: 100%;
}

.embla-viewport {
  overflow: hidden;
  // width: 100%;
}

.embla-container {
  display: flex;
}

.embla-slide {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  // justify-content: center;
  max-width: 100%;
}

.embla-slide-inner {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.embla-button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;

  svg {
    display: block;
  }

  .embla-prev {
    @include red-theme;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
    padding: 0;
    width: 37px;
    height: 40px;
    background-color: #b61615c2;
  }

  .embla-next {
    @include red-theme;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
    padding: 0;
    width: 37px;
    height: 40px;
    background-color: #b61615c2;
  }

  @include breakpoint("medium") {
    width: 100%;
  }
}

.embla-dots {
  display: flex;
  list-style: none;
  justify-content: center;
  padding-top: 1rem;
  margin-bottom: 3rem;
}

%dot {
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border: 2px solid $yellow;
}

.embla-dot {
  @extend %dot;
  background-color: transparent;
}

.embla-dot-active {
  @extend %dot;
  background-color: $yellow;
}

/*---------- Mapbox ----------*/

.mapbox-container {
  margin: 0;
}

.map-container {
  height: 50vh;
  width: 100%;
  overflow: hidden;

  a {
    font-size: 0.75rem;
  }

  @include breakpoint("medium") {
    padding-right: 40px;
    height: 70vh;
  }
}

.marker {
  background-image: url("/mapMarker.svg");
  background-size: cover;
  width: 135px;
  height: 84px;
  cursor: pointer;
  padding-top: 12px;
  font-family: "SL Gothic Text Medium";
  font-size: 1.45rem;
  text-align: center;
  font-weight: medium;
  color: $dark-green;
  margin-left: -34px;
  margin-top: -33px;
}

/*---------- Footer ----------*/

footer {
  background-color: $navigation-green;

  h3,
  a,
  p {
    font-size: $body-font-size;
    display: inline-block;
    width: 100%;
    margin: 1px 0;
    padding: 0;
    line-height: 125%;
  }

  p {
    font-family: "SL Gothic Text Regular";
  }

  h3 {
    font-family: "SL Gothic Text Bold";
    letter-spacing: 0.5px;

    @include breakpoint("medium") {
      font-size: $body-font-size;
      line-height: 100%;
    }
  }

  svg {
    fill: $yellow;
  }

  a {
    @include breakpoint("medium") {
      &:hover {
        color: white;

        svg {
          fill: white;
        }
      }
    }
  }

  .external-link {
    display: flex;
    align-items: center;
    white-space: nowrap;

    svg {
      margin-left: 0.5rem;
    }
  }

  .main-footer {
    display: flex;
    background-color: transparent;
    flex-direction: column;

    @include breakpoint("medium") {
      padding: 2rem 1.25rem;
      flex-direction: row;
    }

    .column-container {
      padding-top: 0.5rem;
      margin: 1.75rem 1.25rem;
      position: relative;
      width: auto;

      @include breakpoint("medium") {
        width: 100%;
      }

      &:not(:first-child) {
        border-top: 2px solid #ffcd05;
      }
    }

    .socials-wrapper {
      .socials {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.5rem;

        a {
          width: auto;
        }
      }

      svg {
        margin-right: 1rem;
      }
    }
  }

  .footer-bottom {
    padding: 0.5rem 0 2rem;
    border-top: 2px solid #ffcd05;
    margin: 1.55rem 1rem 0;
    font-family: "SL Gothic Text Regular";
    font-size: 1rem;
    background-color: transparent;

    @include breakpoint("medium") {
      margin: 1rem 2.5rem 0;
      border-top: 2px solid #ffcd05;
    }
  }
}

/*---------- Accessibility page ----------*/

.accessibility {
  padding: 2.5rem 1rem 2.5rem 1rem;

  @include breakpoint("medium") {
    padding: 5rem 2.5rem 2.5rem;
    position: relative;
    z-index: 1;
    height: auto;
    margin: 0;
    min-width: auto;
    max-width: 600px;
  }

  @include breakpoint("small") {
    min-width: auto;
    max-width: 500px;
  }

  h1 {
    font-family: "SL Gothic Display Bold";
    font-size: $title-font-size;
    margin-top: 0;
    margin-bottom: $body-font-size-large;
    letter-spacing: -1px;
    line-height: 110%;

    @include breakpoint("medium") {
      font-size: $header-font-size;
      line-height: 100%;
      width: 100%;
    }
  }

  h2 {
    font-family: "SL Gothic Display Bold";
    color: $yellow;
    font-size: $body-font-size-large;
    letter-spacing: 0;
    line-height: 110%;
    margin-bottom: -1rem;
    margin-top: 2rem;

    @include breakpoint("medium") {
      font-size: $body-font-size-large;
      line-height: 100%;
      width: 100%;
    }
  }

  h3 {
    font-family: "SL Gothic Text Regular";
    font-size: $small-font-size;
    font-weight: normal;
    color: $yellow;
    margin-top: 2rem;
  }

  ul {
    padding-left: 1rem;

    li {
      list-style: none;
    }
    li:before {
      content: "•";
      position: absolute;
      left: 16px;
      font-size: 20px;
      line-height: 26px;

      @include breakpoint("medium") {
        left: 42px;
      }
    }
  }

  p,
  li {
    font-size: $body-font-size;
    color: $yellow;
  }

  a {
    color: $yellow;
    font-family: "SL Gothic Text Regular";

    &:hover {
      color: white;
    }
  }
}
